import { EnumToArray } from "../../../utils/enumToArray";

enum Kind {
  Default = 'default',
  Blackfriday = 'blackfriday',
}

export const PTZCardTagConfigList = {
  Kind: EnumToArray([Kind]),
};

export const PTZCardTagConfig = {
  Kind,
};
